body {
    @apply bg-white;

    display: initial;
}

.site-content {
    a {
        color: inherit;

        &:after {
            @apply font-mono text-indigo-600;

            content: ' <'attr(href)'>';
            font-size: smaller;
        }
    }
}

.letter-grade {
    @apply hidden;
}

.card {
    @apply px-0 py-0 shadow-none rounded-none;
}

.scan-results-heading,
.data-table {
    a {
        @apply text-indigo-600;

        &:after {
            content: '';
        }
    }
}

.table-wrap {
    @apply border-none;
}

.data-table {
    th {
        @apply border-b;
    }
}

.report-section {
    break-before: page;
}

.audit {
    @apply mb-12;

    break-inside: avoid;
}

.audit-name {
    @apply text-xl mb-3;
}

.audit-stats {
    @apply mb-3;

    > div {
        padding: 0!important;
    }
}

.scan-metadata {
    > div {
        @apply border-b border-gray-200;
    }
}
